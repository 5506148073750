import React from "react"
import bannerStyles from "./banner.module.css";
import { css } from "@emotion/core"

export default (props) => {

    const componentCss = css`
        height: 301px;
        margin-bottom: 10px;
        width: 1024px;
    `

    const panelHeaderCss = css`
        align-items: center;
        background-image: url(${props.imgPath});
        display: flex;
        flex-direction: column;
        height: 301px;
        position: absolute;
        width: 1024px;
    `

    return (
        <div css={componentCss}>
            <div css={panelHeaderCss}>
                <strong><p className={bannerStyles.bannerTitle}>{props.title}</p></strong>
                <strong><p className={bannerStyles.moduleName}>{props.module}</p></strong>
                <div className={bannerStyles.bannerSubtitle}>
                    <strong><p>{props.subtitle}</p></strong>
                </div>
            </div>
        </div>
    )
}